import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div class="app-header">
      <h1><i>Line caught data, delivered fresh</i></h1>
    </div>
    <div class="home-wrapper">
      <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/priceleeks-beta/priceleeks-screenshot.png" class="home-screenshot" />
    </div>



    <div class="boxed">
      <h4>Does PriceLeeks app work worldwide</h4>
      <p>You can use the PriceLeeks App anywhere in the world, however, Our lists of Vendors and Service providers are US based only at the moment.</p>

      <br/>
      <h4>Are the listing shown to me updated</h4>
      <p>We are keeping our records updated in the best of our ability. If you find some information that are outdated, please let us know at <Link to="mailto:support@priceleeks.com">support@priceleeks.com</Link> and we will contact the Vendors or Service providers to give us their updated informations.</p>

      <br/>
      <h4>How should we contact you for inquiries regarding the App</h4>
      <p>If you are having any problems using the PriceLeeks App or you have some inquiries regarding the PriceLeeks App, you may reach us at <Link to="mailto:support@priceleeks.com">support@priceleeks.com</Link></p>

    </div>

    <div class="support-links">
      <Link to="mailto:support@priceleeks.com">Email Support</Link>
      <Link to="/privacy-policy">Privacy Policy</Link>
    </div>
  </Layout>
)

export default IndexPage
